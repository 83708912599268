import React from "react"
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import Seo from "../components/Seo/Seo"
import { getNewestDate } from "../lib/utils"
import { graphql } from "gatsby"

export const query = graphql`
    query ($id: String) {
        datoCmsMainPage(id: {eq: $id}) {
            id
            title
            slug
            contentNode {
                childMarkdownRemark {
                    html
                }
            }
            meta {
                updatedAt
            }
            pageSeoMeta {
                title
                description
                image {
                    url(imgixParams: {fit: "crop", h: "628", w: "1200"})
                }
            }
        }
    }`

const Page = ({ data }) => {
    let newestDate = getNewestDate([data.datoCmsMainPage.meta.updatedAt])

    return (
        <Layout>
            <Seo
                title={data.datoCmsMainPage.title}
                description={data.datoCmsMainPage.description}
                image={data.datoCmsMainPage.image ? data.datoCmsMainPage.image.url : null}
                pathname={data.datoCmsMainPage.slug}
                article
            />
            <main className="bg-zone-13">
                <div className="container mx-auto">
                    <PageTitle
                        newestDate={newestDate}
                        title={data.datoCmsMainPage.title} />

                    <div className="pb-8 page-content px-4 xl:px-0" dangerouslySetInnerHTML={{ __html: data.datoCmsMainPage.contentNode.childMarkdownRemark.html }} />
                </div>
            </main>
        </Layout>
    )
}

export default Page

